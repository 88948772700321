import momemt from 'moment';
import 'slick-carousel'
import 'magnific-popup';
jQuery(document).ready(function ($) {

  $('.menu-open').click(function () {
    $('.menu').addClass('active')
  })

  $('.menu-close, .nav a').click(function () {
    $('.menu').removeClass('active')
  })

  $('.slider-music').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      }
    ]
  });

  $('.slider-videos').slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      }
    ]
  });

  $('.slider-shop').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: 'unslick',
      }
    ]
  });

  $('.bio-all').click(function () {
    $('.bio').toggleClass('all')
    const text = $(this).html()
    if (text === 'Read more') {
      $(this).html('Read less')
    } else {
      $(this).html('Read more')
    }
  })

  $('.slider-videos .video-item a').on('click', function (e) {
    e.preventDefault()
    const featuredVideo = $('#videos iframe')
    const targetVid = $(this).attr('data-video-id')
    console.log(targetVid)
    $(featuredVideo).attr('src', `https://www.youtube.com/embed/${targetVid}`)
  })

  $.ajax({
    url: 'https://rest.bandsintown.com/artists/Ben%20Goldsmith/events?app_id=45PRESS_BEN_GOLDSMITH',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching events!');
    },
    success: data => {
      const events = $('#events');
      let html = '';
      if (data.length) {
        for (let event of data) {
          let region = event.venue.region ? event.venue.region : event.venue.country;
          let location = event.venue.city + ', ' + region;
          if (location === ', ') { location = ''; }
          if (event.venue.name.toLowerCase() === 'streaming live') { location = 'Online'; }
          html += '<div class="event">';
          html += '<div class="event-date">' + momemt(event.datetime).format('MMM D') + '</div>';
          html += '<div class="event-venue">' + event.venue.name + '</div>';
          html += '<div class="event-location">' + location + '</div>';
          html += '<div class="event-links">';
          for (let offer of event.offers) {
            html += '<a href="' + offer.url + '" target="_blank" class="btn btn-border">' + offer.type + '</a>';
          }
          html += '</div>';
          html += '</div>';
        }
        if (data.length > 6) {
          html += '<a href="#" class="seeallbtn btn">See All Dates</a>';
        }
        events.html(html);
        $('.seeallbtn').on('click', function (e) {
          e.preventDefault();
          $('#events').addClass('seeall');
          $(this).hide();
        })
      } else {
        events.html('No upcoming events.');
      }
    }
  });

  // $.magnificPopup.open({
  //   items: {
  //     src: '#popup',
  //     type: 'inline'
  //   }
  // });

  $('.newsletter').on('submit', function (e) {
    e.preventDefault();
    const DATA = $(this).serialize();

    $.ajax({
      type: 'POST',
      url: $(this).attr('action'),
      dataType: 'json',
      data: DATA,
      xhrFields: {
        withCredentials: false,
      },
      success: function (data) {
        $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
      },
      error: function (err) {
        console.log(err);
      }
    });
  });
});